/**
 * Adaptive Placeholders
 */

(function($) {
  'use strict';

  Drupal.behaviors.adaptivePlaceholders = {
    attached: false,

    labelMode: function($input) {
      $input.addClass('js-label-mode');
      $input.removeClass('js-placeholder-mode');
    },

    placeholderMode: function($input) {
      $input.removeClass('js-label-mode');
      $input.addClass('js-placeholder-mode');
    },

    toggleMode: function($input) {
      var self = this;

      if ($input.val() === '') {
        self.placeholderMode($input);
      } else {
        self.labelMode($input);
      }
    },

    bindEvents: function($input) {
      var self = this;

      // swap out placeholder/label classes on focus in or out
      $input.on('focusin', function() {
        self.labelMode($input);
      });

      $input.on('focusout', function() {
        self.toggleMode($input);
      });

      $input.on('change', function() {
        self.toggleMode($input);
      });
    },

    setupDOM: function($inputs) {
      var self = this;

      $inputs.each(function() {
        var $input = $(this);
        if (!$input.hasClass('adpl--processed')) {
          var $label = $input.siblings('label');
          var placeholder = $input.attr('placeholder') || $label.attr('placeholder');

          // input needs a placeholder
          if (!placeholder) {
            return true;
          }

          // if label exists
          if ($label.length > 0) {
            $label.remove(); // hold in space
          } else {
            // if label does not exist, build it
            var id = $input.attr('id');
            if (!!id) {
              $label = $('<label class="label" for="' + id + '">' + placeholder + '</label>');
            } else {
              // if there is no label, and no id on the input, then we cannot proceed
              return true;
            }
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('placeholder')) {
            $label.attr('placeholder', placeholder);
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('alt')) {
            $label.attr('alt', placeholder);
          }

          // ensure that label contains an inner span.label-content wrapping the text
          if ($label.find('span.label-content').length < 1) {
            $label.wrapInner('<span class="label-content"></span>');
          }

          // position the label after the input, required for proper z-index
          $label.insertAfter($input);

          // cleanup inputs
          if ($input.attr('id') == 'google_autocomplete') {
            // google_autcomplete can't have its placeholder attribute removed without
            // it reverting to show the default, so use empty string instead
            $input.attr('placeholder','');
          } else {
            $input.removeAttr('placeholder');
          }

          // set states, bind events
          self.placeholderMode($input);
          self.bindEvents($input);
          self.toggleMode($input);

          // add CSS class for styling
          $input.addClass('adpl--processed');
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      if ($('html').hasClass('no-placeholder')) {
        return;
      }

      var $inputs = $('input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea', context).not('.no-adpl');
      self.setupDOM($inputs);
    }
  };
})(jQuery);

var site = site || {};
/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */


(function($){

  Drupal.behaviors.clickable = {

    attach: function(context, settings) {
      var emailClone;
      var newsletterContainer;
      var emailOverlay;
      var stickyMenuClose;
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        if ($(this).hasClass('js-enable-newsletter-signup')) {
          return false;
        }
        if (window.location.hash) {
          history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
        }
        window.location.href = $(this).data('clickable');
      });

      if (Unison.fetch.now().name === 'small') {
        emailClone = $('.js-email-signup', context).parents('.js-sticky-menu-subnav-content').clone(true, true);
        newsletterContainer = $('<div/>').addClass('email-overlay js-email-overlay sticky__menu__formatter').append(emailClone);
        $('body').append(newsletterContainer);
        emailOverlay = $('.js-email-overlay', context);
        stickyMenuClose = emailOverlay.find('.js-sticky__menu__subnav--close');

        stickyMenuClose.add(emailOverlay).off('click').on('click', function(e) {
          if ($(e.target).parents('.js-email-overlay').length !== 1 || $(this).hasClass('js-sticky__menu__subnav--close')) {
            stickyMenuClose.parents('.js-email-overlay').removeClass('active');
          }
        });
      }

      $('.js-enable-newsletter-signup', context).on('click', function(event) {
        event.preventDefault();
        if (Unison.fetch.now().name === 'small') {
          $('.js-email-overlay').addClass('active');
        } else {
          $('.js-utility-email-signup').trigger('click');
        }
      });
    }
  };
})(jQuery);

/**
 * Generic expander functionality
 * case for basic expanding menu/ul
 *
 * Expanding Menu Usage / example markup:
 *
 *  <ul class="collapsible-menu">
 *    <li class="collapsible-menu__parent">
 *      <h3 class="collapsible-menu-trigger js-collapsible-menu-trigger">Parent Header</h3>
 *      <div class="collapsible-sub-menu">
 *        <ul class="">
 *          <li class=""><a class="" href="#">Link 1</a></li>
 *          <li class=""><a class="" href="#">Link 2</a></li>
 *          <li class=""><a class="" href="#">Link 3</a></li>
 *        </ul>
 *      </div>
 *    </li>
 *  </ul>
 *
 * static link variant inside menu
 *
 *  <ul class="collapsible-menu cs-menu"><li>
 *  		<h3 class="collapsible-menu__static cs-menu__static"><a href="/customer-service-contact-us">Contact Us</a></h3>
 *  	</li>
 *  </ul>
 *
 * Styles applied:
 * .collapsible-menu__parent - plus when closed, minus when open
 * .collapsible-sub-menu - closed by default
 * additional BEM classes can be added for styling
 *
 *
 * Expanding Mobile Block Usage / example markup:
 *  <section class="content-container collapsible-block">
 *    <a class="collapsible-block__anchor" name="cs-orders-section1" id="cs-orders-section4"></a>
 *
 *    <header class="collapsible-block__header clearfix">
 *      <h4 class="collapsible-block__title collapsible-block__title--pc mobile-hidden">Order Cancellations</h4>
 *      <div class="collapsible-block__title collapsible-block__title--mobile pc-hidden js-collapsible-block-trigger">
 *        <span>Order Cancellations</span>
 *        <i class="collapsible-block__icon"></i>
 *      </div>
 *    </header>
 *
 *    <div class="collapsible-block__content">
 *      <div class="clearfix"></div>
 *      <div class="collapsible-block__content-tout clearfix">
 *        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non gravida quam. Ut sed ultricies neque. Quisque pulvinar turpis vel magna iaculis vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec posuere interdum purus, quis porttitor dolor porta ac.
 *      </div>
 *
 *    </div>
 *  </section>
 */

 var site = site || {};

 (function($){

  /**
   * Basic collapsible-menu behavior. Used in custom markup.
   */
  Drupal.behaviors.collapsibleMenu = {
    attach: function(context) {
      $('.js-collapsible-menu-trigger', context).on('click', function() {
        var $parentGrid = $(this).closest('.js-grid');
        $(this).closest('.collapsible-menu__parent').toggleClass('collapsible-menu__parent--expanded');
        // if we are inside a grid, recalculate heights on click
        if ($parentGrid.length) {
          $parentGrid.trigger('grid.reflow');
        }
      });
    }
  };


  /**
   * Basic collapsible-block behavior.
   */
  Drupal.behaviors.collapsibleBlock = {
    attach: function(context) {

      var $block = $('.collapsible-block', context);
      var $parentGrid = $block.closest('.js-grid');

      $block.each(function() {

        if ($(this).hasClass('collapsible-block--mobile-collapsed')) {
          $(this).addClass('collapsible-block--alt');
        }

        var $trigger = $(this).find('.js-collapsible-block-trigger');
        // default expansion
        $trigger.once().on('click', function() {
          $(this).closest('.collapsible-block').toggleClass('collapsible-block--alt');
          // if we are inside a grid, recalculate heights on click
          var $contentblock = $(this).parents('.collapsible-block').children('.collapsible-block__content');
          $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true');
          if ($parentGrid.length) {
            $parentGrid.trigger('grid.reflow');
          }
          $contentblock.toggleClass('hidden');
        });

        //Keyboard Event For ADA Compliance
        $trigger.on('keydown', function(e) {
          if (site.getKeycode(e) === 13) {
            $(this).trigger('click');
          }
        });
      });
    }
  };

})(jQuery);

/**
 * @namespace
 */
var site = site || {};
var generic = generic || {};
site.signin = site.signin || {};

(function($, site) {
  Drupal.behaviors.forgot_password = {
  attach: function(context) {

  /**
   * One-time call to collect specific RB Keys used for forget password.
   * @methodOf site.signin
   */

  site.signin.getRBKeys = function() {
    site.signin.rb = generic.rb("error_messages");
    site.signin.forgotPasswordEmailNotFound   = site.signin.rb.get('incorrect_pwremind');
    site.signin.forgotPasswordNoEmailProvided = site.translations.product.pc_email_address || "Please enter your email address.";
    site.signin.forgotPasswordMigratedUser    = site.signin.rb.get('migrated.mobile_account.signin');
  }

  /**
    * This method is used to set up the forget password functionality
    * on the site.
    * Takes the passed element in the DOM and gets the required form
    * nodes and places them within forgotPassArgs object.
    * site.signin.setForgetPassword() is then called if the param resetPassword
    * is set to true.
    * @param {Object} args
    * @param {Object} args.emailNode **REQUIRED** DOM element of either a
    * form element or wrapper element of the email.
    * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
    * password hint or error messaging if hint is not available.
    * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
    * forget password link.
    * @params {element} forget link node set on dom:load
    * @methodOf site.signin
  */
  site.signin.forgotPassword = function(args) {
    if ((args.emailNode.length > 1) || (!args.forgotPasswordLink) || (!args.errorListNode) ) {
      return null;
    }

    site.signin.getRBKeys();

    var errorListNode = args.errorListNode;
    var emailNode = args.emailNode;
    var forgotPasswordLink = args.forgotPasswordLink;
    var forgotPasswordNote = args.forgotPasswordNote;
    var forgotPasswordCopy = $("#lpw-text");
    // content may have been set on server side. If so, do not hide.
    if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length<1) {
        forgotPasswordCopy.hide();
    }

    forgotPasswordLink.bind('click', function(evt) {
      evt.preventDefault();

      forgotPasswordCopy.show();
      var email = site.signin.getEmailAddress(emailNode);

      if (email.length < 1) {
        $('.js-return-user .error_messages').empty();
        $('.js-return-user .error_messages').append("<li class='s' id='signin_error.email_address.'>" + site.signin.forgotPasswordNoEmailProvided + "</li>");

        // remove errors from new user section div
        $('#new-account > .error_messages').empty();
            var $activeForms = $(this).parents('.js-return-user');
            var $error_message = $activeForms.find('.js-error-messages');
            generic.focusErrors($error_message, $activeForms);
        return null;
      }

      site.signin.requestPassword(email);

      // remove errors from new user section div
      $('#new-account > .error_messages').empty();
      return false;
    });
  };

  /**
    * This method is used to reset a users password by submitting a hidden form.
    * @param {email} the user's email address **REQUIRED**
    * @param {actionURL} the page URL of the reset page **REQUIRED**
    * **NOTE**: The error check for if an account exists is handled by the password
    * hint function. The reset is hidden inside the password hint function
    * so no duplicate error checking is needed here.
  */
  site.signin.initResetPassword = function(emailNode) {
    //have to initialise the link here because it isn't on the page until the pw hint method is invoked
    var email = site.signin.getEmailAddress(emailNode);
    var resetPassLink = $('#pwd-reset');
    if (resetPassLink) {
      resetPassLink.bind('click', function(evt) {
        evt.preventDefault();
        site.signin.requestPassword(email);
      });
    }};


  /**
    * This method is used to direct the user to registration.tmpl or password_request.tmpl.
    * The passed values are injected into the genric form before it is submitted.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {actionURL} action url used on user submit. **REQUIRED**
    * @param {returnURL} passed when an action is needed after the user
    * has gone to the next template page. **NOT REQUIRED**
    * Example case for returnURL is if the user goes through checkout and registers,
    * the returnURL is used to pass the viewbag action url to the registration page. Once
    * registration form is filled out, user will be brought to viewbag.
    * @methodOf site.signin
  */
  site.signin.submitHiddenSigninForm = function(args) {
    if (!args.actionURL || !site.signin.hiddenForm) {
      return null;
    }
    site.signin.hiddenForm.attr('action', args.actionURL);
    var hiddenEmailNode = $('.js-hidden-email');
    hiddenEmailNode.val(args.email);

    if (args.returnURL) {
      var hiddenReturnNode = $('.js-hidden-return');
      hiddenReturnNode.val(args.returnURL);
    }
    site.signin.hiddenForm.submit();
  };

  /**
    * This method is used to call site.signin.submitHiddenSigninForm by
    * passing the user's email used in the reset form submit.
    * @param {String} the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
    */
  site.signin.requestPassword = function(emailAddr) {
    site.signin.hiddenForm = $('.js-signin-hidden-form');
    if (site.signin.hiddenForm) {
      site.signin.submitHiddenSigninForm({
        email: emailAddr,
        actionURL: '/account/password_request.tmpl'
      });
    }
  }

  /**
    * This method is used to pull the user's email from either a form
    * input or container html tag wrapper (i.e. div, span, etc)
    * @param {String} emailNode the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
  */
  site.signin.getEmailAddress = function(emailNode) {
    if(!emailNode ) return null;
    return emailNode.val();
  }

  }
  };

})(jQuery, site);

var site = site || {};

site.globalNavigation = site.globalNavigation || {};

// Cleaning up attach
site.globalNavigation = {
  bps: '',
  bp: '',
  isDesktop: '',
  transitionTime: 350,
  $activeSubnav: 0,
  stickyNav: function () {
    var self = this;
    var lastScrollTop = 0;
    var bannerHeight = self.$banner.height();
    var throttled = _.throttle(function () {
      var scrollTop = $(this).scrollTop();

      if (scrollTop > lastScrollTop && scrollTop > bannerHeight) {
        self.$header.css({
          top: '-' + self.$header.outerHeight() + 'px'
        });
      } else if (scrollTop < lastScrollTop || scrollTop <= bannerHeight) {
        self.$header.css({
          top: '0px'
        });
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }, 10);

    $(window).scroll(throttled);
  },
  breakpoints: function () {
    var self = this;

    self.bps = Unison.fetch.all();
    self.bp = Unison.fetch.now();
    self.isDesktop = parseInt(self.bp.width, 10) >= parseInt(self.bps.medium, 10);
  },
  route: function ($subnav, id) {
    var self = this;

    // Check for open menu
    if (self.$activeSubnav.length < 1 && $subnav.length > 0) {
      self.$activeSubnav = $subnav;
      self.openSubNav(id);
    } else if ($subnav.get(0) !== $(self.$activeSubnav).get(0)) {
      // Check if submenu is the same before closing
      self.closeSubNav($subnav, id);
    }
  },
  openSubNav: function (id) {
    var self = this;
    var $el = $(self.$activeSubnav);

    if (!self.$activeSubnav.first().hasClass('js-site-backdrop-remove')) {
      $el = $el.add(self.$backdrop);
    }
    self.$allSubnavs.stop(true, true).hide();
    $el.fadeIn(self.transitionTime, function () {
      id = id.toLowerCase();
      var triggerName = 'gnav_subnav_open--' + id;

      $(document).trigger(triggerName, $el);
      $(document).trigger('gnav_subnav_open');
    });
  },
  closeSubNav: function ($nextSubnav, id) {
    var self = this;
    var $el = $(self.$activeSubnav).add(self.$backdrop);

    if ($nextSubnav.length) {
      $el.stop(true, true).hide();
      self.$activeSubnav = $nextSubnav;
      self.openSubNav(id);
    } else if (!$nextSubnav) {
      if (self.$activeSubnav.length) {
        $el.fadeOut(self.transitionTime, function () {
          self.$activeSubnav = 0;
        });
      } else {
        /* We lost reference to the current subnav
        close them all */
        self.$allSubnavs.fadeOut(self.transitionTime, function () {});
      }
    }
    self.$utilityNavTrigger.removeClass('active');
    $(document).trigger('gnav_subnav_close');
  },
  slide: function ($item, $el) {
    var self = this;
    // True if not visible
    var show = !$el.is(':visible');

    if (show) {
      /* Right here is a good place to trigger resize so things like hidden
      carousels can get laid out properly now that they're visible */
      $(window).trigger('resize');
    }
    $el.slideToggle(self.transitionTime, function () {
      if (!show) {
        $item.removeClass('active');
      }
    });
  },
  signupTrigger: function (context, settings) {
    var self = this;
    // Open email signup nav on
    var cookieName = 'welcome_15';
    var hasCookie = $.cookie(cookieName);

    // Don't run for pro-users and denied access page
    if ($('html').hasClass('suppress-consumer-ui')) {
      return;
    }
    if (_.isNull(hasCookie)) {
      // Short pause to let the page settle
      setTimeout(function () {
        if (self.isDesktop) {
          // Open subnav
          self.route(self.$emailSubnav, 'email');
          if (site && site.elcEvents) {
            /* MTA-5240 Trigger overlay display event when pop up displays
               on clicking Join Now button from offer page */
            if (window.document.readyState === 'complete' && typeof utag !== 'undefined') {
              $(document).trigger('welcome_overlay_displayed', [{ template_name: 'email_signup_block_v1' }]);
            } else {
              // MTA-5240 Trigger overlay display event when pop up displays on page load
              site.elcEvents.addListener('track:ready', function () {
                $(document).trigger('welcome_overlay_displayed', [{ template_name: 'email_signup_block_v1' }]);
              });
            }
          }
        } else if (!self.isDesktop) {
          $(document).trigger('sticky_email_open');
        }
      }, 3000);
      $.cookie(cookieName, '1', {
        expires: 1,
        path: '/'
      });
    }
    /* When sign_up_popup_timer (= 7 minute), then the signUp popup should
       be displayed also after 7 minute, not only on the site entry 'displaySignUp'
       cookie is seted when the popUp displayes after 7 min, or if the user already
       submitted his email, to know not display it again 'firstEntryDate' cookie is
       used to record firs user entry on site and to calculate his time spent on the site */
    var sign_up_popup_timer = settings.sign_up_popup_timer;

    if (sign_up_popup_timer) {
      var displaySignUp = 'displaySignUp';
      var shouldDisplay = $.cookie(displaySignUp);

      if (_.isNull(shouldDisplay)) {
        var firstEntryDate = 'firstEntryDate';
        var firstEntry = $.cookie(firstEntryDate);

        if (_.isNull(firstEntry)) {
          $.cookie(firstEntryDate, new Date().getTime(), {
            expires: 1,
            path: '/'
          });
        } else {
          var nowDate = new Date().getTime();
          var timeDiff = nowDate - parseInt($.cookie(firstEntryDate));

          if (timeDiff >= (1000 * 60 * sign_up_popup_timer)) {
            if (self.isDesktop) {
              self.route(self.$emailSubnav, 'email');
              if (site && site.elcEvents) {
                /* MTA-5240 Trigger overlay display event when pop up
                displays on clicking Join Now button from offer page */
                if (window.document.readyState === 'complete' && typeof utag !== 'undefined') {
                  $(document).trigger('welcome_overlay_displayed', [{ template_name: 'email_signup_block_v1' }]);
                } else {
                  // MTA-5240 Trigger overlay display event when pop up displays on page load
                  site.elcEvents.addListener('track:ready', function () {
                    $(document).trigger('welcome_overlay_displayed', [{ template_name: 'email_signup_block_v1' }]);
                  });
                }
              }
            } else {
              $(document).trigger('sticky_email_open');
            }
            $.cookie(displaySignUp, '1', {
              expires: 1,
              path: '/'
            });
          }
        }
      }
    }
  },
  addEvents: function (context, settings) {
    var self = this;
    var id = '';
    var $subnav = $();
    var timeout;

    self.$primaryItemLinks.on('mouseenter focusin', function () {
      if (!self.isDesktop) {
        return;
      }

      id = $(this).text();
      $subnav = $('> .menu--lvl-2a', $(this).parent());

      // Delay the opening of the Gnav menu.
      timeout = setTimeout(function () {
        self.$primaryItemLinks.attr('aria-expanded', 'true');
        self.route($subnav, id);
      }, 200);
    });

    self.$primaryItems.on('mouseleave', function () {
      clearTimeout(timeout);
      self.closeSubNav(0);
      self.$primaryItemLinks.attr('aria-expanded', 'false');
    });

    // Close Subnav For focusing non children menu items
    self.$primaryNonVisibleLink.on('focusin', function () {
      self.closeSubNav(0);
    });

    self.$backdrop.once().on('click', function () {
      self.closeSubNav(0);
    });

    self.$primaryMenuClose.once().on('click', function (event) {
      event.preventDefault();
      self.closeSubNav(0);
    });

    // Mobile click should act normally.
    self.$locatorTriggers.on('click keydown', function (event) {
      if (event.isTrigger === true || event.which === 1 || event.which === 13) {
        event.preventDefault();
        if (self.isDesktop) {
          event.preventDefault();
          if ($(this).attr('aria-expanded') === 'true') {
            $('.js-gnav__locator__close').trigger('click');
            $(this).attr('aria-expanded', 'false');
          } else {
            self.route(self.$locatorSubnav, 'locator');
            $(this).attr('aria-expanded', 'true');
            $('.js-gnav__locator').focus();
            site.restrict_navigation(self.$locatorSubnav);
          }
        }
      }
    });

    $('.js-gnav__locator__search__submit').focusout(function () {
      $(this).parents('.js-gnav__locator').find('a:visible').focus();
    });

    self.$emailTriggers.once().on('click keydown', function (event) {
      if (event.isTrigger === true || event.which === 1 || event.which === 13) {
        event.preventDefault();
        self.route(self.$emailSubnav, 'email');
        $(this).attr('aria-expanded', 'true');
        site.restrict_navigation(self.$emailSubnav);
      }
    });

    $('.js-site-email-signup__submit').on('keydown', function (e) {
      if (site.getKeycode(e) === 9) {
        setTimeout(function () {
          $('.js-site-email-signup__submit').parents('.js-email-signup').find('a:visible').focus();
        }, 50);
      }
    });

    self.$bannerClose.once().on('click', function () {
      self.closeSubNav(0);
    });

    $('.js-gnav__locator__close').on('click keydown', function (e) {
      var $locatorHeader = $(this).parents('.site-header').find('.js-site-header__store__locator');

      if (site.getKeycode(e) === 13 || site.getKeycode(e) === 1) {
        $(this).trigger('click');
        $locatorHeader.attr('aria-expanded', 'false').focus();
      }
    });

    self.lastElementVisible.on('focusout', function () {
      self.closeSubNav(0);
    });

    $('.js-site-email-signup__close').on('click keydown', function (e) {
      var $emailHeader = $(this).parents('.site-header').find('.js-site-header__email');

      if (site.getKeycode(e) === 13 || site.getKeycode(e) === 1) {
        self.closeSubNav(0);
        $emailHeader.attr('aria-expanded', 'false').focus();
      }
    });

    self.$emailSubnav.bind('cbox_closed', function () {
      if (self.$emailSubnav.is(':visible')) {
        self.closeSubNav(0);
      }
    });

    $(document).on('gnav_subnav_open--locator', function (event, el) {
      var $input = $('#locator-search', el);

      $input.addClass('custom-outline').focus();
    });

    $(document).on('gnav_subnav_open--utility', function (event, el) {
      var $input = $('.js-signin-block__field--email', el);

      if ($input.length) {
        $('.js-signin-block__register').focus();
      }
    });

    $(document).on('gnav_subnav_open--email', function (event, el) {
      if (!self.isDesktop) {
        return;
      }
      var $input = $('input[name="PC_EMAIL_ADDRESS"]', el);

      if ($input.length) {
        $('.js-email-signup').first().focus();
      }
    });

    $(document).on('close_all_subnav', function () {
      self.closeSubNav(0);
    });

    // Mobile hamburger trigger handled in utility_nav_formatter.js

    // Mobile
    self.$mainNavDropdownTriggers.once().on('click', function (event) {
      if (self.isDesktop) {
        return;
      }
      event.preventDefault();
      var $item = $(this).toggleClass('active');
      var $menu = $item.next('.menu');
      // Hide the other items that dont have active
      var $otherOpenItems = $('.menu__link--has-children.active', $(this).closest('.site-header__primary__menu')).not($item);

      // Slide the others based on whether the mobile nav is open & active
      if ($otherOpenItems.length) {
        var $otherMenus = $('+ .menu', $otherOpenItems.get(0));

        $otherMenus.slideUp(self.transitionTime, function () {
          $otherOpenItems.removeClass('active');
          self.slide($item, $menu, 'menu__item--selected');
        });
      } else {
        self.slide($item, $menu, 'menu__item--selected');
      }
    });

    /* Mobile
     self.$primaryItemLinks.on('click', function (event) {
       if (self.isDesktop) { return; }
       event.preventDefault();
     });*/

    Unison.on('change', function () {
      self.bp = Unison.fetch.now();
      self.isDesktop = parseInt(self.bp.width, 10) >= parseInt(self.bps.medium, 10);
      if (self.isDesktop) {
        // Above medium remove all instances of 'menu__item--selected'
        $('.menu__item', self.$mainNav).removeClass('menu__item--selected');
        self.$body.removeClass('mobile-gnav-active');
      } else {
        // Below medium collapse any active menus
        self.closeSubNav(0);
      }

      // Close active mobile menu:
      self.closeSubNav(0);
    });

    self.signupTrigger(context, settings);
  },
  getDom: function (context, settings) {
    var self = this;

    // Check context when reattaching drupal behaviors
    if (context !== document) {
      return;
    }
    self.$body = $('body', context);
    self.$header = $('.site-header', self.$pageWrapper);
    self.$pageWrapper = $('.page-wrapper', context);
    self.$banner = $('.js-nav--banner', self.$header);
    self.$mainNav = $('.site-header__section--nav', self.$header);
    self.$utilityNavContent = $('.js-gnav-util__content', self.$header);
    self.$utilityNavTrigger = $('.js-gnav-util-trigger', self.$header);
    self.$primaryNav = $('.js-nav--primary', self.$header);
    self.$primaryNavMenus = $('.js-menu__formatter__list', self.$mainNav);
    self.$primaryVisible = self.$primaryNavMenus.find(':visible.menu__item', self.$mainNav);
    self.$primaryNonVisibleLink = self.$primaryVisible.children('a:not(.menu__link--has-children)', self.$mainNav);
    self.$mainNavDropdownTriggers = $('.menu__link--has-children', self.$mainNav);
    self.$primaryItems = self.$primaryNavMenus.children().has('.menu');
    self.$primaryItemLinks = self.$primaryItems.children('.menu__link');
    self.$primaryMenuClose = $('.js-menu__close', self.$mainNav);
    self.childrenlinkVisible = $('.menu__link--has-children:last:visible', self.$primaryNav);
    self.lastElementVisible = self.childrenlinkVisible.next().find('a:last');
    // Locator
    self.$locatorTriggers = $('.js-site-header__store__locator', self.$header);
    self.$locatorSubnav = $('.js-gnav__locator', self.$header);
    // Email
    self.$emailTriggers = $('.js-site-header__email', self.$header);
    self.$emailSubnav = $('.js-email-signup', self.$header);
    // Combined subnavs
    self.$bannerSubnavs = self.$locatorSubnav.add(self.$emailSubnav);
    self.$bannerClose = $('.js-gnav__locator__close, .js-site-email-signup__close', self.$header);
    self.$backdrop = $('.js-content-dim', context);
    // Subnavs
    self.$primarySubnavs = $('> .menu--lvl-2a', self.$primaryItems);
    self.$allSubnavs = self.$primarySubnavs.add(self.$bannerSubnavs).add(self.$utilityNavContent);

    self.breakpoints();
    self.addEvents(context, settings);
  },
  init: function (context, settings) {
    var self = this;

    self.getDom(context, settings);
    site.globalNavigation.stickyNav();
  }
};

// Method used to get keyCode value of keyboard event triggered.
site.getKeycode = function (e) {
  var keycode = e.keyCode ? e.keyCode : e.which;

  return keycode;
};

// Method used to retain Tab Focus within selected container.
site.restrict_navigation = function (element) {
  element.on('keydown', function (event) {
    if (event.keyCode !== 9 || event.isDefaultPrevented()) {
      return;
    }
    var $tabbables = element.find(':tabbable');
    var $first = $tabbables.filter(':first');
    var $last = $tabbables.filter(':last');

    if ((event.target === $last[0] || event.target === element[0]) && !event.shiftKey) {
      $first.focus();
      event.preventDefault();
    } else if ((event.target === $first[0] || event.target === element[0]) && event.shiftKey) {
      $last.focus();
      event.preventDefault();
    }
  });
};

(function () {
  Drupal.behaviors.gnav = {
    attach: function (context, settings) {
      site.globalNavigation.init(context, settings);
      if (Drupal.settings.common.enable_gnav_revamp) {
        $('body').addClass('enable-gnav__revamp');
      }
    }
  };
})(jQuery);

var site = site || {};
site.template = site.template || {};

(function($) {

Drupal.behaviors.offers = {
  linkedBasedOffer: function() {
    if ($.cookie('offer_info') == null || $.cookie('offer_info').length == 0) {
      return;
    }
    var offer_code = $.cookie('offer_info').split(":")[1];
    site.onLoadRpc.requests.push({
      method: 'offer.linkedOfferState',
      params: [offer_code],
      onSuccess: function(data) {
        var message = data.result.value.linked_offer.offer_message;
        var template = offer_code;
        var rendered = site.template.get({
          name: template,
          data: {message : message}
        });
        generic.overlay.launch({
          content: rendered
        });
      },
      onError: function(data) {}
    });
  },

  attach: function(context, settings) {
    if (this.attached) {
      return;
    }
    this.attached = true;

    this.linkedBasedOffer();
  },

  attached: false
};

})(jQuery);

(function($, site, generic) {
  site.onLoadRpc = site.onLoadRpc || {};
  site.onLoadRpc.requests = site.onLoadRpc.requests || [];

  site.onLoadRpc.init = function() {
    // User stuff
    var signedIn = site.userInfoCookie.getValue('signed_in') - 0 === 1;
    if (signedIn) {
      site.onLoadRpc.requests.push({
        method: 'user.fullData',
        params: [{}],
        onSuccess: function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
          if (!data || !data.result) {
            return;
          }

          var val = data.result.value;

          if ((val.csr_email !== undefined) && (val.csr_email !== null)) {
            $('#csr_header_holder').removeClass('hidden');
          }

          // Set a body class attribute based on user logged in status
          $('body').addClass(val.signed_in === 1 ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous');

          if (Drupal.settings.common.enable_gnav_revamp) {
            if ($('body').hasClass('elc-user-state-logged-in')) {
              $('.js-gnav-util__account').addClass('hidden');
            }
          }

          // Populate user data
          $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
            var $me = $(this);
            $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
          });

          // generic.user.setUser(val);

          $(document).trigger('user.loaded', val);
        },
        onFailure: function() {}
      });
    } else {
    // generic.user.setUser({});
      $('body').addClass('elc-user-state-anonymous');
      $(document).trigger('user.loaded', {});
    }
  };

  /*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
  site.onLoadRpc.fetch = function() {
    var requests = site.onLoadRpc.requests || [];
    var rLen = requests.length;
    var queryVals = [];

    for (var i = 0, len = rLen; i < len; i++) {
      var postMethod = requests[i].method || 'rpc.form';

      if (typeof requests[i].getParams === 'function') {
        requests[i].params = requests[i].getParams();
      }

      queryVals[i] = {
        method: postMethod,
        params: requests[i].params,
        id: i + 1
      };
    }

    if (!queryVals.length) {
      return;
    }

    var successHandler = function(data) {
      for (var i = 0, len = rLen; i < len; i++) {
        var fn = requests[i].onSuccess;
        if (typeof fn !== 'function') {
          continue;
        }
        fn(data[i]);
      }
    };

    var url = generic.jsonrpc.url || '/rpc/jsonrpc.tmpl';
    var options = {};

    // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
    // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
    // This snippets searches for such entries and adds 'em to the request url.
    var url_params = '';
    $(queryVals).each(function() {
      if (this.params[0].url_params) {
        if (this.params[0].url_params.charAt(0) === '&') {
          url_params += this.params[0].url_params;
        } else {
          url_params += '&' + this.params[0].url_params;
        }
      }
    });
    if (url_params !== '') {
      url += '?' + url_params.substring(1);
    }

    options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

    options.type = 'POST';
    options.success = function(data, textStatus, response) {
      successHandler(data, textStatus, response);
    };
    options.error = function(jqXHR, textStatus, errorThrown) {
      console.log(jqXHR, textStatus, errorThrown);
    };
    $.ajax(url, options);
  };

  $(function() {
    $(document).trigger('onLoadRpc.fetch');
    // Set user cookie
    site.userInfoCookie.init();
    site.onLoadRpc.init();
    site.onLoadRpc.fetch();

    if (window.location.hash === '#sign-in') {
      setTimeout(function () {
        $('#return-user-link').trigger('click');
      }, 500);
    }
    if (window.location.hash === '#new-account') {
      setTimeout(function () {
        $('#new-account-link').trigger('click');
      }, 500);
    }
  });
})(jQuery, window.site || {}, window.generic || {});

(function($, generic) {

  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch : function(args, event) {
      if (typeof event !== 'undefined' ) {
        event.preventDefault();
      }
      var focused = document.activeElement;
      // ColorBox args sent along
      var cboxArgs = {
          'height': 'auto',
          'width' : '768px',
        'margin': 'auto',
        onClosed: function() {
          focused.focus();
        }
        };
      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides
      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        _.extend(cboxArgs, {height: '100%', width: '100%'});
      }
      // Actual content of the overlay
      if (typeof args.content !== 'undefined') cboxArgs.html = args.content;
      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') cboxArgs.className = args.cssClass;
      // Scroll to an anchor, if sent over
      if (typeof args.inPageAnchor !== 'undefined') {
        cboxArgs.onComplete = function() {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        };
      }
      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each( function() {
        var args = {
            cssStyle: {}
          }, // args sent to overlay
          linkClassNames = $(this).attr('class'), // class name sent to colorbox
          linkHref = $(this).attr('href'), // actual href
          linkHrefWithEmbed = linkHref,
          inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
          overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          var pathName = urlParts.pathname;
          try {
            if ((generic.env.isIE || generic.env.isIE11) && pathName.charAt(0) !== '/') {
              pathName = '/' + pathName;
            }
            if( !linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = pathName + (urlParts.search === '' ? '?' : urlParts.search + '&') + 'embed=1' + urlParts.hash;

              // Retain original link if it included the protocol.
              if(linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + "//" + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch(e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
          $('#cboxLoadedContent').attr('tabindex', 0).focus();
        });

      }); // .each()

    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb("language");
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  ($)(function(){
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });

})(jQuery, window.generic || {});

var site = site || {};
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
window.pwr = window.pwr || function () {
  (pwr.q = pwr.q || []).push(arguments);
};

(function($) {
  var pr_products = [];
  var drupalPRSettings = Drupal.settings.analytics ? Drupal.settings.analytics.power_reviews_enh_enabled : false;
  // Set up PowerReviews data on product:init
  $(document).on('product:init', '.js-product', function() {
    if (typeof pwr === 'function' && typeof Drupal.settings.power_reviews != 'undefined') {
      var product = prodcat.data.getProduct($(this).data('product-id'));
      if (product === null) {
        return;
      }
      var PROD_BASE_VAL = product.PRODUCT_ID.replace(/\D/g, '');
      var reviewsnippet_id = 'pr-reviewsnippet-' + product.PROD_BASE_ID + '-' + (new Date()).getTime();
      var $reviewsnippet = $('.review-snippet', this);
      $reviewsnippet.attr('id', reviewsnippet_id);

      if (product && $reviewsnippet.length && !$reviewsnippet.hasClass('reviews-processed')) {
        $reviewsnippet.addClass('reviews-processed');

        var page_id = Drupal.settings.power_reviews.page_id || product.PROD_BASE_ID || PROD_BASE_VAL;
        var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?page_id=' + page_id;
        var pr_product = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: Drupal.settings.power_reviews.locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: Drupal.settings.power_reviews.merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_render: function(config, data) {
            // MTA-208 Analytics enhancements for read/write reviews Starts
            if (drupalPRSettings) {
              // To disable community answer button from ask and answer section
              if (Drupal.settings.power_reviews.hide_add_answer_button) {
                $('.pr-qa-display-question').addClass('add-your-answer-button--hide');
              }

              // Track sort option in reviews page
              $('#pr-rd-sort-by').once().on('change', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var obj = {
                    sort_option: $('option:selected', this).text()
                  };
                  site.track.sortReviews(obj);
                }
              });

              // Track write revew button in reviews section
              $('.spp_reviews_button').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  site.track.reviewWriteStart();
                }
              });

              // Track write revew button in reviews section
              $('.pr-snippet-review-count').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  site.track.reviewRead();
                }
              });
            }

            // MTA-208 Analytics enhancements for read/write reviews Ends
          },
          components: {
            ReviewSnippet: reviewsnippet_id
          }
        };
        if ($(this).hasClass('product-full')) {
          pr_product.on_read_reviews_click = function() {
            $('.js-pr-rating-tab', $('.responsive-tabs--powerreviews')).click();
            $('html, body').animate({
              'scrollTop': $('#pr-reviewdisplay').offset().top - 175
            }, 1000);
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewRead();
            }
          };
          pr_product.on_write_review_click = function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewWriteStart();
            }
            var war_link = $('.pr-snippet-write-review-link').attr('href');
            if (typeof war_link !== 'undefined' && war_link !== '') {
              window.location.href = war_link;
            }
          };
          pr_product.components.ReviewDisplay = 'pr-reviewdisplay';
          var image_url = typeof product.IMAGE_L == 'object' ? encodeURI(product.IMAGE_L[0]) : encodeURI(product.IMAGE_L);
          var url = encodeURI(product.url);
          pr_product.structured_data_product_id = 'pr_seo_snippet';
          pr_product.product = {
            name: product.PROD_RGN_NAME,
            url: 'http://' + document.location.hostname + url,
            image_url: 'http://' + document.location.hostname + image_url,
            description: strip_html_tags(product.DESCRIPTION),
            category_name: product.DEFAULT_CAT_ID,
            upc: product.defaultSku.UPC_CODE,
            brand_name: 'Bumble',
            price: product.defaultSku.PRICE,
            in_stock: (!!product.isShoppable).toString(),
          };
        }

        pr_products.push(pr_product);
      }
      if (Drupal.settings.power_reviews.show_ask_question && $('.product-full').length > 0 && !$('#pr-questionsnippet').hasClass('question-processed')) {
        $('#pr-questionsnippet').addClass('question-processed');
        $('.js-pr-ask-tab').removeClass('hidden');

        var pr_product_question = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: Drupal.settings.power_reviews.locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: Drupal.settings.power_reviews.merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_render: function(config, data) {
            var question_count = data.question_count;
            if (question_count > 0) {
              $('.pr-ask-question-link').addClass('hidden');
              if (!$('.pr-snippet-qa-answer-link').hasClass('count-rendered')) {
                $('.pr-snippet-qa-answer-link').append(' ' + question_count);
                $('.pr-snippet-qa-answer-link').addClass('count-rendered');
              }
            } else {
              $('.pr-snippet-qa-metrics').addClass('hidden');
            }
            // MTA-208 Analytics enhancements for Ask Answer section Starts
            if (drupalPRSettings) {
              // Tracking while start asking a question
              $('#pr-question-form-link').click(function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel') {
                  site.track.questionAsk();
                }
              });

              // Tracking sort option in ask and answer section
              $('#pr-qa-sort-by').once().on('change', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var obj = {
                    sort_option: $('option:selected', this).text()
                  };
                  site.track.sortAnswers(obj);
                }
              });

              // Tracking thumbs up rating for an answer
              $('.pr-helpful-yes').once('js-thumbs-up').each(function() {
                $(this).on('click', function() {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    var obj = {
                      event_action: 'thumbs up'
                    };
                    site.track.rateAnswers(obj);
                  }
                });
              });

              // Tracking thumbs down rating for an answer
              $('.pr-helpful-no').once('js-thumbs-down').each(function() {
                $(this).on('click', function() {
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                    var obj = {
                      event_action: 'thumbs down'
                    };
                    site.track.rateAnswers(obj);
                  }
                });
              });

              // Tracking search action in ask and answer section
              $('.pr-qa-search-btn').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var searchTerm = $(this).siblings('#pr-query').attr('value');
                  if (searchTerm && searchTerm !== '') {
                    var obj = {
                      search_term: searchTerm
                    };
                    site.track.questionAskSearch(obj);
                  }
                }
              });
            }
          },

          // Tracking while submitting a question
          on_submit: function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAnswer();
            }
          },
          // MTA-208 Analytics enhancements for Ask Answer section Ends

          on_view_answers_click: function() {
            $('.js-pr-ask-tab').click();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top
            }, 1000);
            // MTA-208 Tracking while start reading Q & A section
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAsk();
            }
          },
          on_ask_question_click: function() {
            $('.js-pr-ask-tab').click();
            var headerHeight = $('.js-site-header').height() + $('.spp-sticky__container').height();
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top - headerHeight
            }, 1000);
            // MTA-208 Tracking while start reading Q & A section
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
              site.track.questionAsk();
            }
          },
          components: {
            QuestionSnippet: 'pr-questionsnippet',
            QuestionDisplay: 'pr-questiondisplay',
          }
        };
        pr_products.push(pr_product_question);
      }
    }
  });

  $('ul.tabs-nav li').click(function() {
    var tab_id = $(this).attr('data-tab');
    $('.tabs-content').hide();
    $('ul.tabs-nav li').removeClass('tab-active');
    $('.' + tab_id).show();
    $(this).addClass('tab-active');
  });

  // MTA-208 Tracking while submitting an answer for question
  $(document).on('click', '.pr-answer-form .pr-submit .pr-btn-review', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.addAnswerEnd();
    }
  });

  // MTA-208 Track thumbs up rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-208 Track thumbs down  rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-208 Track flagging reviews in review page
  $(document).on('click', '.pr-rd-flag-review-container', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() === 'Flag this review' && drupalPRSettings) {
      var obj = {
        event_action: 'flag this review'
      };
      site.track.rateReviews(obj);
    }
  });

  // Tracking while start adding an answer for a question
  $(document).on('click', '.pr-btn-answer', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && $(this).text() !== 'Cancel' && drupalPRSettings) {
      site.track.addAnswerStart();
    }
  });

  // Tracking show more answers click
  $(document).on('click', '.pr-qa-display-show-more-questions', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      site.track.showMoreAnswers();
    }
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-start', function() {
    pr_products = [];
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-finish', function() {
    if (typeof pwr === 'function' && typeof Drupal.settings.power_reviews != 'undefined') {
      pwr('render', pr_products);
    }
  });

  var $container = $('.product-full__ratings');
  $container.on('click', '.pr-snippet-review-count', function(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    var $sppSection = $('.spp__container');
    $('html, body').animate({
      'scrollTop': $('.spp_customer_reviews', $sppSection).offset().top - 200
    }, 2000);
  });

  function strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  }
})(jQuery);

var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {

prodcat.data.collectProductIds = function($context) {
  var prodIds = [];
  $('[data-product-id]', $context).each(function() {
    var $this = $(this);
    var prodId = $this.attr('data-product-id');
    // Check if a prodId exists
    if (!prodId) {
      return null;
    };
    var insert = true;
    for (var i = prodIds.length - 1; i > -1; i--) {
      if (prodIds[i] === prodId) {
        insert = false;
        break;
      }
    }
    if (insert) {
      prodIds.push(prodId);
    }
    insert = true;
  });
  return prodIds;
};

/**
 * Retrieves product data from data store. Folds full sku data into product.skus array
 * @param {String} prodId
 */
prodcat.data.getProduct = function(prodId) {
  if (!prodcat.data.store.products[prodId]) {
    return null;
  }
  var prodData = $.extend( true, {}, prodcat.data.store.products[prodId] );
  _.each(prodData.skus, function(skuId, idx) {
    prodData.skus[idx] = prodcat.data.getSku(skuId);
  });
  return prodData;
};

prodcat.data.getSku = function(skuId) {
  skuId = skuId + ''; // Has to be a string to run indexOf
  skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
  var skuData = prodcat.data.store.skus[skuId];
  if (!skuData){
    return null;
  }
  return skuData;
};

/**
 * Sanitize the product data to ensure a consistent data structure
 */
prodcat.data.sanitizeProductData = function(product) {
  // Force the following fields to be an array even if they're originally a string
  var pf = ['IMAGE_L', 'IMAGE_M', 'IMAGE_S', 'IMAGE_XL', 'IMAGE_XM', 'IMAGE_XXL'],
      fk = '';
  for (var i = pf.length; i--;) {
    fk = pf[i];
    if (_.isUndefined(product[fk])) {
      continue;
    }
    product[fk] = (typeof product[fk] === 'string') ? [ product[fk] ] : product[fk];
  }

  return product;
};


$(document).on('prodcat.products.update', function(e, productsArray, deepCopy) {
  prodcat.data.updateProducts(productsArray, deepCopy);
});

prodcat.data.updateProducts = function(productsArray, deepCopy) {
  var self = this;
  deepCopy = (deepCopy === false) ? deepCopy : true;  // do a deep copy of the product data by default
  _.each(productsArray, function(newProd) {
    if (!newProd) {
      return;
    }
    var targetProd = $.extend(deepCopy, {}, newProd);
    var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

    if (_.isArray(newProd.skus)) {
      prodcat.data.updateSkus(targetProd.skus);
      targetProd.skus = _.map(targetProd.skus, function(sku) {
        return sku.SKU_ID;
      });
    }

    var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);

    // Set defaultSku to the first sku if not already set:
    if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
      prod.defaultSku = self.getSku(prod.skus[0]);
    }

    prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
  });

  $(document).trigger('prodcat.products.updated', prodcat.data.store.products);
};

/**
 * Sanitize the sku data to ensure a consistent data structure
 */
prodcat.data.sanitizeSkuData = function(sku) {
  // Remove any "product" keys from the sku object to prevent recursion errors down the road.
  sku.product = undefined;

  // Force the following fields to be an array even if they're originally a string
  var sf = ['IMAGE_SMOOSH_L', 'IMAGE_SMOOSH_S', 'IMAGE_SMOOSH_XL'],
      fk = '';
  for (var i = sf.length; i--;) {
    fk = sf[i];
    if (_.isUndefined(sku[fk])) {
      continue;
    }
    sku[fk] = (typeof sku[fk] === 'string') ? [ sku[fk] ] : sku[fk];
  }

  return sku;
};

prodcat.data.updateSkus = function(skusArray) {
  _.each(skusArray, function(newSku) {
    if (!newSku.PRODUCT_ID && newSku.product && newSku.product.PRODUCT_ID) {
        newSku.PRODUCT_ID = newSku.product.PRODUCT_ID;
    }

    newSku = prodcat.data.sanitizeSkuData(newSku);
    var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
    prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
  });
};

prodcat.data.init = function() {
  prodcat.data.store = {
    categories: {},
    products: {},
    skus: {}
  };

  if (typeof page_data === "undefined" || !page_data) {
    return null;
  }

  function _catStore(newCat) {
    var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
    var targetCat = $.extend(true, {}, newCat);
    if (_.isArray(targetCat.products)) {
      prodcat.data.updateProducts(targetCat.products);
    }
    targetCat.products = _.map(targetCat.products, function(prod) {
      return prod.PRODUCT_ID;
    });
    prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
  }

  for (var key in page_data) {
    if (!page_data[key]) {
      continue;
    }
    if (page_data[key].categories && _.isArray(page_data[key].categories)) {
      _.each(page_data[key].categories, _catStore);
    }
    if (page_data[key].products && _.isArray(page_data[key].products)) {
      prodcat.data.updateProducts(page_data[key].products);
    }
    if (page_data[key].product && _.isObject(page_data[key].product)) {
      $(document).trigger('prodcat.products.update', [page_data[key].product]);
      prodcat.data.updateProducts([page_data[key].product]);
    }
  }

  $(document).trigger('prodcat.data.initalized', prodcat.data.store);
};

prodcat.data.pids = prodcat.data.collectProductIds();
// Prevent making an empty call if no prodIds are available.
if (prodcat.data.pids.length > 0) {
  site.onLoadRpc.requests.push({
    method:   "prodcat.querykey",
    getParams: function() {
      var pids = prodcat.data.pids;
      return [{
        products: pids,
        query_key: 'catalog-mpp-volatile'
      }];
    },
    onSuccess: function (r) {
      if (
        !r ||
        !r.result ||
        !r.result.value ||
        !r.result.value.products
      ) {
        return;
      }
  
      var prods = _.compact(r.result.value.products);
      prodcat.data.updateProducts(prods);

      _.each(prods, function(prod) {
        var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
        $(prodSlctr).trigger("inv_status_data:updated");
      });

      if (prods.length > 0) { // can't think of a reason why this WOULD be empty, but check, just in case
        // trigger a custom event, letting all who care know that we've updated inventory status data for every product
        $(document).trigger("inv_status_data:finished");
      }

      $(document).trigger('prodcat.data.query.success');
    }
  });
}

prodcat.data.isPaletteMultiSku = function(prodId) {
  var prodData = prodcat.data.getProduct(prodId);
  return !!prodData.isPaletteMultiSku;
};

/**
 * Pull data from page_data and store internally.
 */
Drupal.behaviors.prodcatDataInit = {
  attached: false,
  attach: function() {
    if (!this.attached) {
      prodcat.data.init();
    }
    this.attached = true;
  }
};

})(jQuery);

var site = site || {};
var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function($, generic) {
  /**
   * Adds SKUs to cart.
   */
  prodcat.ui.addToCart = function(args) {
    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }

    var quantity = args.quantity || 1;

    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        catBaseId = matchResult[0];
      }
    }

    args.skus = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
    args.itemType = args.itemType || 'cart';
    args.QTY = quantity || args.QTY;
    args.INCREMENT = 1; // INCREMENT only needs to be true.

    // Conditionally add a CAT_BASE_ID key to the list of parameters to send
    var cbid = args.CAT_BASE_ID || catBaseId;
    if (cbid.length > 0) {
      args.CAT_BASE_ID = cbid;
    }

    generic.checkout.cart.updateCart({
      params: args,
      onSuccess: function(r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });
  };

  /**
   * Adds a SKU to the user's primary favorites list.
   * @param {Object} args
   * @param {Object} args.skuData a set of key-value pairs describing a SKU
   * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
   * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
   * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
   */
  prodcat.ui.addToFavorites = function(args) {
    var params = {
      _SUBMIT: 'alter_collection',
      action: 'add'
    };

    if (args.action) {
      params.action = args.action;
    }

    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }
    params.SKU_BASE_ID = skuBaseId;

    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        params.CAT_BASE_ID = matchResult[0];
      }
    }

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function(jsonRpcResponse) {
        var d = jsonRpcResponse.getData();
        var r = d.ac_results[0].result;
        var cr;
        if (params.action !== 'delete') {
          if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.exists', [cr, args.$el]);
          } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.success', [cr, args.$el]);
          }
          $(document).trigger('product.favorites', [args.$el, 'success']);
        } else {
          $(document).trigger('product.favorites', [args.$el, 'removed']);
        }
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
      }
    });
  };

  $(document).on('product.favorites', function(e, $el, action) {
    var $icon = $el.find('.icon');

    if (action === 'success') {
      $icon.removeClass('icon--utility_outlined_heart').addClass('icon--utility_filled_heart');
      $el.tooltipster('enable').tooltipster('show');
    }

    if (action === 'removed') {
      $icon.removeClass('icon--utility_filled_heart').addClass('icon--utility_outlined_heart');
      $el.tooltipster('enable').tooltipster('show');
    }
  });

  $(document).on('addToCart.failure addToWishlist.failure', function(event, errorObjectsArray) {
    // Escape any html in the alert box.
    var prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
    alert(prodAddedMsg);
  });

  prodcat.ui.grids = (function() {
    var grids = [];
    return $.extend(prodcat.ui.grids || {}, {
      add: function(grid) {
        if (_.indexOf(grids, grid) === -1) { // if we can't find the grid in our list
          grids.push(grid);
        }
      },
      clean: function(args) {
        if (args.before && typeof args.before === 'function') {
          args.before(grids);
        }
        site.util.grids.attach(grids);
        if (args.after && typeof args.before === 'function') {
          args.after(grids);
        }
        grids = [];
      }
    });
  }());

  /***********************
   * Add-to-favorites button
   ***********************
  */
  var tooltipsterDefaultSettings = {
    animation: 'fade',
    arrow: false,
    contentAsHTML: true,
    interactive: true,
    multiple: true,
    onlyOne: true,
    position: 'top',
    restoration: 'none',
    speed: 500,
    theme: 'tooltipster-container',
    timer: 3000,
    trigger: 'click',
    updateAnimation: false
  };

  $(document).on('product.initFavoritesTooltip', function(e, $product) {
    //console.log('product.initFavoritesTooltip');
    var $faveLink = $product.find('.js-add-to-favorites');
    if ($faveLink.length) {
      $faveLink.tooltipster(tooltipsterDefaultSettings);
    }
  });

  $(document).on('click', '.js-add-to-favorites', function(e) {
    e.preventDefault();
    var $this = $(this);
    var skuBaseId = $this.data('sku-base-id');
    var $icon = $this.find('.icon');

    $this.tooltipster('disable').tooltipster('hide', function() {
      if ($icon.hasClass('icon--utility_filled_heart')) {
        $this.tooltipster('content', $this.data('tooltip-removed'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'delete'});
      } else {
        $this.tooltipster('content', $this.data('tooltip-success'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'add'});
      }
    });
  });

  // Default product listeners:

  $(document).on('product:init', '.js-product', function() {
    var $product = $(this);
    // Select the default sku if set:
    var skuBaseId = $(this).data('sku-base-id');
    var routerActions = {
      changeSppShade: function(shadeName) {
        if (!$product.hasClass('product-full')) {
          return;
        }

        var shadeNameNoSpaces = decodeURIComponent(shadeName).split('_').join(' ');
        var prodData = prodcat.data.getProduct($product.data('product-id'));
        _.each(prodData.skus, function(sku) {
          if (shadeNameNoSpaces === sku.SHADENAME) {
            skuBaseId = sku.SKU_BASE_ID;
            $product.trigger('product.skuSelect', [skuBaseId]);
          }
        });
      },

      changeSku: function(skuID) {
        if (!$product.is('.product-full, .product-quickshop')) {
          return;
        }
        var skuData = prodcat.data.getSku(skuID);
        if (skuData) {
          skuBaseId = skuID;
          $product.trigger('product.skuSelect', [skuBaseId]);
        }
      }
    };
    var routes = {
      '/shade/:shadeName': routerActions.changeSppShade,
      '/sku/:skuID': routerActions.changeSku,
    };

    var router = Router(routes);
    router.init();

    $(document).on('product.updateRoute', function(e, type, path) {
      if (type === 'shade') {
        router.setRoute('shade/' + path.split(' ').join('_'));
      } else {
        router.setRoute('sku/' + path);
      }
    });

    if (!!skuBaseId) {
      $(this).trigger('product.skuSelect', [skuBaseId]);
    }

    // update inventory status:
    $(this).trigger('product.updateInvStatus');

    $(this).trigger('product.initFavoritesTooltip', [$product]);
  });

  $(document).on('product.updateInvStatus', '.js-product', function() {
    var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
    var skuBaseId = $addBtn.data('sku-base-id') || $(this).data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);
    var $parentGrid;
    var parentGridOverrideStatus;
    var reorderStatuses = [ // these are the statuses that will trigger a product block to be reordered
      2, // Temporarily Sold Out
      3, // Coming Soon
      7 // Sold Out
    ];
    var reorderTest = function(l2Cache) {
      return _.contains(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
    };
    var reorderSoldOut = function(skuDataL2, el) {
      var $el = $(el);

      if (!_.isArray(skuDataL2)) {
        skuDataL2 = [ skuDataL2 ];
      }

      if (_.every(skuDataL2, reorderTest)) {
        $el.closest('.js-grid-item').appendTo($el.closest('.js-grid-item').parent());
        prodcat.ui.grids.add($el.closest('.js-grid').get(0));
      }
    };

    if (!$addBtn.length) {
      return;
    }

    $parentGrid = $(this).parents('.js-product-grid');
    parentGridOverrideStatus = $parentGrid.data('sold-out-reordering-override');
    // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
    if ($parentGrid.is('.js-sold-out-reordering') && parentGridOverrideStatus !== 'off' || parentGridOverrideStatus === 'on') {
      reorderSoldOut(prodcat.data.getProduct($(this).data('product-id')).skus, this);
    }

    var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;

    if (skuDataL2 && !skuDataL2.isShoppable) {
      $addBtn.addClass('button--disabled').data('disabled', true);

      if (hasWaitlist && (skuDataL2.INVENTORY_STATUS - 0 === 3 || skuDataL2.INVENTORY_STATUS - 0 === 7)) {
        $(document).trigger('product.waitlist.init', skuDataL2);
      }
    } else {
      if (hasWaitlist && skuDataL2) {
        $(document).trigger('product.waitlist.reset', skuDataL2);
      }

      $addBtn.removeClass('button--disabled').data('disabled', false);
    }
  });

  $(document).on('product.updateInvStatusFinished', function() {
    prodcat.ui.grids.clean({
      before: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.reset($grid);
          }
        });
      },
      after: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.init($grid);
          }
        });
      }
    });
  });

  $(document).on('product.quickshopInit', function(e, methods) {
    prodcat.ui.quickshop = _.extend(prodcat.ui.quickshop || {}, methods);
  });

  $(document).on('product.skuSelect', '.js-product', function(e, skuBaseId, context) {
    $(this).data('sku-base-id', skuBaseId);
    $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
    $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);
    var $sppPage = $('.spp__container', context);

    if ($sppPage) {
      $('.js-add-to-cart, .js-add-to-bag', this).add('.js-spp-product-info-sticky .js-add-to-cart').data('sku-base-id', skuBaseId);
    }
    $(this).trigger('product.updateInvStatus');
  });

  $(document).on('inv_status_data:updated', '.js-product', function() {
    $(this).trigger('product.updateInvStatus');
  });

  $(document).on('inv_status_data:finished', function() {
    $(this).trigger('product.updateInvStatusFinished');
  });

  // SPP links open in new window if spp_in_new_window=true in config
  if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
    $(document).on('click', '.js-spp-link', function() {
      $(this).attr('target', '_blank');
    });
  }

  $(document).on('click', '.js-add-to-cart, .js-add-to-bag', function(e) {
    e.preventDefault();
    var $addBtn = $(this);
    if ($addBtn.data('disabled')) {
      return;
    }
    var skuBaseId = $addBtn.data('sku-base-id');
    if (!skuBaseId || skuBaseId.length < 1) {
      return;
    }

    // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
    $(document).trigger('addToCart.toggle', [$addBtn]);

    // Account for adding multiple skus to bag
    var args = {};
    skuBaseId = String(skuBaseId);
    if (skuBaseId.indexOf(',') >= 0) {
      // clean the string
      skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
      args.skuBaseId = skuBaseId.split(',');
      if (skuBaseId.slice(-1) === ',') {
        args.skuBaseId.pop();
      }
    } else {
      args.skuBaseId = skuBaseId;
    }

    var quantity = $addBtn.data('qty');
    if (!!quantity) {
      args.quantity = quantity;
    }

    // Replenishment updates when sku is refillable and enable_replenishment=true in config.
    //  (currently applicable only when adding one item at a time)
    if (_.isString(args.skuBaseId) && Drupal.settings.common && Drupal.settings.common.has_replenishment) {
      var skuDataL2 = prodcat.data.getSku(args.skuBaseId);
      if (skuDataL2.REFILLABLE) {
        var frequency = $addBtn.attr('data-replenishment');
        if (!!frequency) {
          args.REPLENISHMENT_FREQ = frequency;
        } else {
          args.REPLENISHMENT_FREQ = 0;
        }
        args.action = 'add';
        args.itemType = 'replenishment';
        args.add_to_cart = 1;
      }
    }

    args.$addBtn = $addBtn;

    prodcat.ui.addToCart(args);
  });

  /*
  ***********************
  * display text fields (price, shade name, etc)
  ***********************
  */
  $(document).on('product.skuSelect product.skuDisplay', '.js-product', function(e, skuBaseId) {
    $(this).trigger('product.updateText', [skuBaseId]);
  });

  $(document).on('product.updateText', '.js-product', function(e, skuBaseId) {
    var textFields = [
      {
        selector: 'js-product-size',
        field: 'PRODUCT_SIZE'
      },
      {
        selector: 'js-product-price',
        field: 'formattedPrice'
      },
      {
        selector: 'js-sale-price',
        field: 'formattedPrice'
      },
      {
        selector: 'js-original-price',
        field: 'formattedPrice2'
      },
      {
        selector: 'js-sku-shade-name',
        field: 'SHADENAME'
      },
      {
        selector: 'js-sku-unit-price',
        field: 'formattedUnitPrice'
      },
      {
        selector: 'js-sku-shade-description',
        field: 'SHADE_DESCRIPTION'
      }
    ];
    var skuData = prodcat.data.getSku(skuBaseId);
    var $productEl = $(this);

    if (typeof skuData['PRICE2'] === 'number') {
      $productEl.find('.js-original-price').addClass('original-price');
      $productEl.find('.js-sale-price').addClass('sale-price');
    } else {
      $productEl.find('.js-original-price').removeClass('original-price');
      $productEl.find('.js-sale-price').removeClass('sale-price');
    }

    _.each(textFields, function(el) {
      var $el = $('.' + el.selector, $productEl);

      if ($el.length < 1
        || (el.selector === 'js-product-price' && $el.hasClass('product-brief-show_price2'))) {
        return;
      }

      $el.html(skuData[el.field]);
    });
  });

  /**
   * Toggles the visibility of an add-to button, and its sibling loading message
   */
  $(document).on('addToCart.toggle', function(event, $addBtn) {
    if (!$addBtn || $addBtn.length < 1) {
      return;
    }

    $addBtn.toggleClass('hidden');

    var loadingDiv = $addBtn.siblings('.js-loading-message').eq(0);

    if (loadingDiv && loadingDiv.length > 0) {
      loadingDiv.toggleClass('hidden');
    }
  });

  /**
   * Wire up behavior on page-load according to js- classnames.
   */
  Drupal.behaviors.prodcatUiInit = {
    attach: function(context) {
      $(context).trigger('products.init-start');
      $('.js-product', context).trigger('product:init');
      $(context).trigger('products.init-finish');
    }
  };
})(jQuery, window.generic = window.generic || {});

var site = site || {};
var generic = generic || {};
(function($) {
  'use strict';

  Drupal.behaviors.salonTakeover = {
    getSalonID: function() {
      // get SalonID from URL parameter or session
      var result;
      var parsedQuery = generic.env.parsedQuery();
      var salonIDParam = parsedQuery['salon_id'];
      var salonIDSession = sessionStorage.getItem('salon_id');
      var salonList = site.translations.salon.salon_list;

      if (salonIDParam) {
        if (salonIDParam !== salonIDSession) {
          // Set new SalonID to sessionStorage
          this.setSalonID(salonIDParam);
        }
        result = salonIDParam;
      } else {
        result = salonIDSession;
      }
      if (result && salonList) {
        // else check for the current salon ID and respective urls against IDs given in the CMS
        var salonListSplit = salonList.split('|');
        var salonListLength = salonListSplit.length;
        for (var i = 0; i < salonListLength; i++) {
          var salonValues = salonListSplit[i].split(',');
          if (salonValues[0] === result && window.sessionStorage) {
            sessionStorage.setItem('logo_cta', salonValues[1]);
            return result;
          }
        }
      }
      return false;
    },
    setSalonID: function(salonID) {
      // store SalonID
      if (window.sessionStorage) {
        sessionStorage.setItem('salon_id', salonID);
      }
    },
    updateVisibility: function() {
      // toggle header elements visibility via css
      $('body').addClass('salon-takeover');
    },
    updateLogo: function(salonID, context) {
      var self = this;
      var $salonHeader = $('.js-salon-site-header', context);
      var $logo = $('.js-logo-salon', context);
      var logoCTA = sessionStorage.getItem('logo_cta');
      $salonHeader.attr('href', '/' + logoCTA);
      $logo.attr('src', '/media/export/cms/salons/logos/' + salonID + '.png');
      self.updateVisibility();
    },
    init: function(context) {
      var self = this;
      var salonID = self.getSalonID();
      if (salonID) {
        self.updateLogo(salonID, context);
      }
    },
    attach: function(context) {
      var self = this;
      var isPro = $('html').hasClass('pro-user');
      // No need to display Salon GNAV if isPro
      if (!isPro && !_.isUndefined(site.translations.salon)) {
        self.init(context);
      }
    }
  };
})(jQuery);

var SelectBox = SelectBox || {};
var site = site || {};

(function($) {

'use strict';
Drupal.behaviors.selectBox = {
  attach: function(context) {
    $('.selectBox:not(.selectBox-attached)', context).each(function() {
        var selectBoxObj = $(this).addClass('selectBox-attached').selectBox({
          mobile: $(this).hasClass('selectBox--yes-even-for-mobile')
        });
        // Adding role and aria-live to custom selectbox for ADA compilance
        var control = selectBoxObj.data('selectBox-control');
        var selectObjLabel = selectBoxObj.attr('name');
        var selectAriaLabel = '';
        var selectLabel = '';
        if (control && selectObjLabel) {
          selectLabel = selectBoxObj.attr('name');
          selectAriaLabel = selectLabel.replace('_', ' ');
          control.children('.selectBox-label').attr('id', selectLabel);
        } else {
          selectLabel = selectBoxObj.children().first().text();
        }
        if (control) {
          var selectBoxRequired = control.prev().prop('required');
          selectLabel = control.children('.selectBox-label').attr('id');
          control.attr({'role': 'combobox', 'aria-expanded': 'false', 'aria-describedby': selectLabel, 'aria-label': selectAriaLabel});
          if (selectBoxRequired) {
            control.attr('aria-required', 'true');
          }
          var selectElements = control.attr('aria-expanded');
          control.keydown(function(e) {
            if (site.getKeycode(e) === 13 || site.getKeycode(e) === 40 || (site.getKeycode(e) === 186 && e.shiftkey)) {
              selectElements = 'false';
              if (control.hasClass('selectBox-menuShowing')) {
                selectElements = 'true';
              }
            }
            $(this).attr('aria-expanded', selectElements);
          });
          control.focusout(function() {
            $(this).attr('aria-expanded', 'false');
          });

          // Custom SelecBox Events updated
          if (control.hasClass('custom-control')) {
            control.off('keydown').on('keydown', function(e) {
              var code = e.keyCode || e.which;
              // To update Custom SelectBox UP/Down/Return Arrow Events
              if (code === 38 || code === 40 || code === 13) {
                customKeydown(control, e);
              } else {
                SelectBox.prototype.selectElement = selectBoxObj[0];
                SelectBox.prototype.handleKeyDown(e);
              }
            });
          }

          function customKeydown($control, e) {
            e.preventDefault();
            var $optionsElement = $control.data('selectBox-options');
            var $currentSelected = $optionsElement.find('.selectBox-selected');
            var $optionsElements = $optionsElement.find('li');
            var $isPrev = $currentSelected.prev();
            var $isNxt = $currentSelected.next();
            var code = e.keyCode || e.which;
            SelectBox.prototype.selectElement = selectBoxObj[0];
            switch (code) {
              case 38: // up
                if ($isPrev.length > 0) {
                  $optionsElements.removeClass('selectBox-selected');
                  $isPrev.addClass('selectBox-selected');
                  SelectBox.prototype.keepOptionInView($isPrev);
                }
                break;
              case 40: // down
                if ($isNxt.length > 0) {
                  $optionsElements.removeClass('selectBox-selected');
                  $isNxt.addClass('selectBox-selected');
                  SelectBox.prototype.keepOptionInView($isNxt);
                }
                break;
              case 13: // return
                if (!$optionsElement.is(':visible')) {
                  SelectBox.prototype.showMenu();
                } else {
                  var $li = $optionsElement.find('.selectBox-selected');
                  var selection = $li.find('a').attr('rel');
                  var $select = $control.prev('select');
                  if ($select.val() !== selection) {
                    $select.val(selection);
                    $select.trigger('change');
                    $optionsElement.hide();
                  }
                }
                break;
            }
          }
        }
      });
    },

  // Use this instead of direclty invoking $('.selectBox').selectBox('refresh').
  // The plugin doesn't take into account the fact that the selectBox may be
  // disabled for touch and will blow up if it is.
  refresh: function($selects) {
    $selects.each(function() {
      var control = $(this).data('selectBox-control');
      if (control && control.length) {
        $(this).selectBox('refresh');
       }
    });
  }
};

})(jQuery);

(function ($){
    siteLogoResponsiveV1 = {
        formatter: $('.site-header__logo__container')
    };
    Drupal.behaviors.siteLogoResponsiveV1 = {
        get: function(key) {
            return siteLogoResponsiveV1[key];
        },

        set: function(key, val) {
            siteLogoResponsiveV1[key] = val;
        },

        attach: function(context, settings) {
            var self = this;
            var page_title = document.title;
            var formatter = self.get('formatter');
            formatter.each(function() {
                var thisFormatter = $(this);
                thisFormatter.before('<h1 class="page-title hidden">'+page_title+'</h1>');
            });
        },
    };
})(jQuery);
(function($) {
  var turnRelativeToAbsolute = function(urlToFix) {
    // If the first URL is a backslash, the URL is relative and Pinterest won't pull it. We need to make this a full URL.
    if (urlToFix.substr(0, 1) === '/') {
      // Split the URL by backslashes
      var urlArray = location.href.split('/');
      // Find the web protocol i.e. http, https
      var protocol = urlArray[0];
      // We know there is always 2 backslashes before the website URL
      // Put the protocol and the base url together
      var websiteUrl = protocol + '//' + urlArray[2];
      // Now add the base url to the relative image url to make it absolute
      urlToFix = websiteUrl + urlToFix;
    }
    return urlToFix;
  };
  $(document).ready(function() {
    // Add media to the pinterest URL
    var $pinterestLink =  $('.js-share--pinterest');
    $pinterestLink.each(function() {
      var $ogImage = $('meta[property="og:image"]');
      var mediaURL = false;
      if ($ogImage.length) {
        mediaURL = $ogImage.attr('content');
      } else {
        // If there's no open graph tag for the time being, find the first product image and use that
        mediaURL = $('.js-product-image').first().attr('src');
      }
      mediaURL = turnRelativeToAbsolute(mediaURL);
      // Append this media to the openURL
      var url = $pinterestLink.attr('href') + '&media=';
      url += encodeURI(mediaURL);
      $pinterestLink.attr('href', url);
    });
  });

  $(document).on('click', '.js-share', function(event) {
    var $this = $(this);
    var $quickShopContainer = $this.closest('.js-quickshop');
    // Email - Modify the URL to strip product name HTML tags before opening
    if ($(this).hasClass('js-share--email')) {
      var subject = $(this).data('subject');
      // Strip all HTML
      subject = $('<div/>').html(subject).text();
      var body = $(this).data('body');
      // Make our mailto: link
      $(this).attr('href', 'mailto:?subject=' + subject + '&body=' + body);
      return;
    }
    // Social Media
    var width = 600;
    var height = 350;
    var openUrl = $(this).attr('href');
    // Allow for borders.
    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    // Allow for title and status bars.
    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = "status=no,height=" + height + ",width=" + width;
    windowFeatures += ",resizable=yes,left=" + leftPosition + ",top=" + topPosition;
    windowFeatures += ",screenX=" + leftPosition + ",screenY=" + topPosition;
    windowFeatures += ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
    // Specifically for the Quickshop on MPP, find and use the media URL for Pinterest sharing
    if ($this.hasClass('js-share--pinterest') && $quickShopContainer.length) {
      openUrl += '&media=' + turnRelativeToAbsolute($quickShopContainer.find('.js-spp-link img').attr('src'));
    }

    window.open(openUrl, 'sharer', windowFeatures);
    event.preventDefault();
  });

})(jQuery);

var site = site || {};
(function($) {
  Drupal.behaviors.stickyPageNavigation = {
    attach: function(context) {
      if (site.templates.site_header_page_navigation === undefined) {
        return false;
      }
      var stickyPageNav = site.template.get({
        name: 'site_header_page_navigation',
      });
      var $stickyHeaderPageNav = $('.js-site-header-page-nav', context);
      $stickyHeaderPageNav.append(stickyPageNav);
      var $anchorContent = $('.js-page-nav-anchor-link', context);
      var $pageNavPc = $('.js-page-nav-pc', $stickyHeaderPageNav);
      var $pageNavMobile = $('.js-page-nav-mobile', $stickyHeaderPageNav);
      var $pageNavAccordionTitle = $('.js-page-nav-accordion-title', $pageNavMobile);
      var $pageNavMobileOptions = $('.js-page-nav-mobile-options', $stickyHeaderPageNav);
      var $pageNavClose = $('.js-page-nav-close', $pageNavMobile);
      var $pageNavDown = $('.js-nav-down', $pageNavMobile);
      var $pageNavAnchor = $('.js-page-nav-anchor-link:first').closest('.js-sticky-page-navigation');
      var headerHeight;
      var pageNavHeight = 20;

      if (!$anchorContent.length) {
        return false;
      }
      $anchorContent.each(function() {
        var $this = $(this);
        var navLink;
        var anchorId;
        var $stickyPageMenu;
        if ($this.attr('data-remove-anchor') === undefined) {
          navLink = $this.attr('name');
          anchorId = navLink.split(' ').join('_');
          $stickyPageMenu = $('<li />').appendTo($pageNavPc);
          $this.attr('id', anchorId);
          var $stickyPageLink = $('<a />').text(navLink).attr('href', '#' + anchorId);
          $stickyPageLink.clone().appendTo($stickyPageMenu);
          $stickyPageLink.clone().appendTo($pageNavMobileOptions);
        }
      });

      $pageNavAccordionTitle.add($pageNavDown).on('click', function(e) {
        e.stopPropagation();
        $pageNavMobileOptions.removeClass('hidden');
        $pageNavClose.addClass('nav-open');
      });

      $pageNavClose.on('click', function() {
        $pageNavClose.removeClass('nav-open');
        $pageNavMobileOptions.addClass('hidden');
      });

      $stickyHeaderPageNav.find('a').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var section = $this.attr('href');
        var currentText = $this.text();
        headerHeight = $('.js-site-header').height();
        var headerSecHight = headerHeight + pageNavHeight;
        if (Unison.fetch.now().name === 'small') {
          var $accordionOptionHeight = $('.js-page-nav-mobile-options').outerHeight(true);
          headerSecHight = headerHeight - $accordionOptionHeight + pageNavHeight;
          $pageNavAccordionTitle.text(currentText);
          $pageNavMobileOptions.addClass('hidden');
          $pageNavClose.removeClass('nav-open');
        }
        pageNavScroll(section, headerSecHight);
      });

      var pageNavScroll = function(anchorSection, headerTop) {
        $('html, body').stop().animate({
          scrollTop: $(anchorSection).offset().top - headerTop
        }, 500);
      };

      var enableScroll = function() {
        var $window = $(window);
        $window.scroll(_.debounce(function(e) {
          e.preventDefault();
          var $firstScrollAnchor = $('.js-page-nav-anchor-link:first');
          var $specificAnchorSelection = $('[data-show-anchor="1"]');
          if ($specificAnchorSelection.length === 1) {
            $firstScrollAnchor = $specificAnchorSelection;
          }
          var scrollPosition = $firstScrollAnchor.parent().height() + $firstScrollAnchor.offset().top - $('.js-site-header').height();
          if ($window.scrollTop() > scrollPosition) {
            $stickyHeaderPageNav.addClass('visible');
          } else {
            $stickyHeaderPageNav.removeClass('visible');
          }
        }, 20));
      };

      if ($pageNavAnchor.attr('data-show-page-nav') !== undefined) {
        $stickyHeaderPageNav.addClass('visible');
      } else {
        enableScroll();
      }
    }
  };
})(jQuery);

window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + "_v(\\d+)$");
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = (!_.isUndefined(site.templates[key])) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function(){},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

// This file includes this commit to work with require.js:
// https://github.com/pouipouidesign/Unison/commit/30b1b0d9fcff831f7c4f92952911902f01284b57
// It also has some customizations to work with IE8.

/* global Unison: true */
Unison = (function() {

  'use strict';

  var win = window;
  var doc = document;
  var head = doc.head;
  var eventCache = {};
  var unisonReady = false;
  var currentBP;

  var util = {
    parseMQ : function(el) {
      var str = this.getStyleProperty(el, 'font-family');
      return str.replace(/"/g, '').replace(/'/g, '');
    },
    getStyleProperty: function(el, attr) {
      if (this.isUndefined(win.getComputedStyle)) {
        attr = attr.replace(/-(.)/g, function(match, group1) {
          return group1.toUpperCase();
        });
        return el.currentStyle[attr];
      } else {
        return win.getComputedStyle(el, null).getPropertyValue(attr);
      }
    },
    debounce : function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
        if (immediate && !timeout) {
          func.apply(context, args);
        }
      };
    },
    isObject : function(e) { return typeof e === 'object'; },
    isUndefined : function(e) { return typeof e === 'undefined'; }
  };

  var events = {
    on : function(event, callback) {
      if ( !util.isObject(eventCache[event]) ) {
        eventCache[event] = [];
      }
      eventCache[event].push(callback);
    },
    emit : function(event, data) {
      if ( util.isObject(eventCache[event]) ) {
        var eventQ = eventCache[event].slice();
        for ( var i = 0; i < eventQ.length; i++ ) {
          eventQ[i].call(this, data);
        }
      }
    }
  };

  var breakpoints = {
    all : function() {
      var BPs = {};
      var allBP = util.parseMQ(doc.querySelector('title')).split(',');
      for ( var i = 0; i < allBP.length; i++ ) {
        var mq = allBP[i].trim().split(' ');
        BPs[mq[0]] = mq[1];
      }
      return ( unisonReady ) ? BPs : null ;
    },
    now : function(callback) {
      var nowBP = util.parseMQ(head).split(' ');
      var now = {
        name : nowBP[0],
        width : nowBP[1]
      };
      return ( unisonReady ) ? (( util.isUndefined(callback) ) ? now : callback(now)) : null ;
    },
    update : function() {
      breakpoints.now(function(bp) {
        if ( bp.name !== currentBP ) {
          events.emit(bp.name);
          events.emit('change', bp);
          currentBP = bp.name;
        }
      });
    }
  };

  if (util.isUndefined(head)) {
    head = document.getElementsByTagName('head')[0];
  }

  win.onresize = util.debounce(breakpoints.update, 100);

  // if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
    unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
    breakpoints.update();
  // } else {
  //   doc.addEventListener('DOMContentLoaded', function(){
  //     unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
  //     breakpoints.update();
  //   });
  // }

  return {
    fetch : {
      all : breakpoints.all,
      now : breakpoints.now
    },
    on : events.on,
    emit : events.emit,
    util : {
      debounce : util.debounce,
      isObject : util.isObject
    }
  };

})();

(function($, generic, site) {

/**
  * Method to grab a cookie and use that to control DOM elements as needed.
  * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
  * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
  * Example cookie structure not signed in:
  *   FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
  * Example cookie structure signed in:
  *   FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
  * You can set specific functions on page load using events.load or hook into javascript events
  *  by defining them in the events class and adding the event to events.init.
  * The cookie class is used to handle all the cookie functionality such as the setting and getting.
  * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
  * Uses straight javascript so not dependent on a javascript framework except for DOM load.
  * Preferably added to the where ever the globalnav javascript is added within a brand.
  * @memberOf site
*/
site.userInfoCookie = function() {
  // Set global vars here.
  var nodes = {};

  // Private internal cookie class.
  // Leverages generic.cookie to get and set the cookie values.
  var cookie = {
    name: 'FE_USER_CART',
    value: '',
    regEx: function(key) {
      if (!key) {
        return null;
      }
      return new RegExp(key + ':([^;&,}]*)');
    },
    set: function() {
      if (!this.name) {
        return null;
      }
      var userCookie = generic.cookie(this.name);
      this.value = userCookie ? userCookie : '';
    },
    getValue: function(key) {
      var keyVal = this.value.match(this.regEx(key));
      return keyVal ? (keyVal[1] ? keyVal[1] : null) : null;
    },
    setValue: function(key, val) {
      var match  = this.value.match(this.regEx(key));
      var oldValue = match[0];
      var newValue = this.value.replace(match[1], val);
      generic.cookie(this.name, newValue, { path: '/' });
      this.value   = newValue;
    }
  };

  // Private events class that handles all individual events.
  // Add all events in 'init' method so they will get fired on page load.
  // The cart event is commented out but there as an example.
  var events = {
    init: function() {
      this.load();
      //this.cart();
    },
    load: function() {
      _setCartItemsTotal();
    },
    cart: function() {
      /*
      $(document).on('cart:countsUpdated', function() {
        var cartCount = generic.checkout.cart.getTotalItems();
        cookie.setValue('item_count', cartCount);
      });
      */
    }
  };

  /* Additional helper functions below here. */

  // Pulls in the cookie info and updates the DOM;
  var _setCartItemsTotal = function() {
    if (!nodes.cartTotalContainer) {
      return null;
    }

    var valueKey = 'item_count';
    var itemsTotal = cookie.getValue(valueKey) - 0 || 0;
    $.each(nodes.cartTotalContainer, function() {
      $(this).html(itemsTotal || '');
    });
  };

  // BRAND SPECIFIC: Get any DOM nodes and assign them to the global class var nodes. Varies between brands.
  // Helps keep all the brand specific DOM definitions in one spot.
  var _getDomNodes = function() {
    nodes.cartTotalContainer = $('.js-cart_count');
  };

  return {
    init: function() {
      _getDomNodes();
      cookie.set();
      events.init();
    },
    set: function() {
      cookie.set();
    },
    getValue: function(key) {
      return cookie.getValue(key);
    },
    setValue: function(key, val) {
      cookie.setValue(key, val);
    }
  };
}();

// Set the cookie outside of $(document).ready so other scripts can always access it in their $(document).ready:
site.userInfoCookie.set();

})(jQuery, window.generic || {}, window.site || {});

(function ($, site, generic) {
  var videoBlock = {
    $videoContent: $(),
    $imageVideoBlock: $(),
    player: {},
    openOverlay: false,
    setup: function ($elementsObj, context) {
      var self = this;
      var $modules = $elementsObj;

      $modules.each(function () {
        var $module = $(this);
        var $play = $('.js-play', $module);
        var $reveal = $('.js-video-reveal', $module);
        var provider = $module.data('video-provider');
        var isYoutube = (provider === 'youtube');
        var isHTML5 = (provider === 'html5');
        var isVimeo = (provider === 'vimeo');
        var $closeVideoButton = $('.js-close-video-button', $module);

        self.$videoContent = $reveal;

        $closeVideoButton.once().on('click', function () {
          self.stopVideo();
        });

        $play.once().on('click', function (e) {
          e.preventDefault();

          if ($play.hasClass('js-play-overlay')) {
            self.openOverlay = true;
          }

          var opts = {
            context: context,
            provider: provider,
            openOverlay: self.openOverlay,
            $content: $reveal
          };

          if (isYoutube === true) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (isVimeo === true) {
            opts.vimeoId = $module.data('vimeo-id');
          } else if (isHTML5 === true) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }

          site.videos.open(opts);

          return false;
        });
      });
    },
    pauseVideo: function () {
      var videoPlayer = this.player;

      if (videoPlayer?.id) {
        videoPlayer.pauseVideo();
      }
    },
    stopVideo: function () {
      var self = this;
      var videoPlayer = self.player;

      if (self.$videoContent.length < 1 || self.$imageVideoBlock < 1) {
        return false;
      }

      if (videoPlayer?.id) {
        videoPlayer.stopVideo();
        self.$imageVideoBlock.removeClass('hidden');
        self.$videoContent
          .addClass('hidden')
          .removeClass('revealed')
          .closest('.slick-initialized')
          .removeClass('slick-initialized--video-playing');
      }
    }
  };

  site.videos = {

    open: function (opts) {
      var provider = !_.isEmpty(opts.provider) ? opts.provider : '';

      if (provider === 'youtube') {
        this.openYouTube(opts);
      } else if (provider === 'vimeo') {
        this.openVimeo(opts);
      } else if (provider === 'html5') {
        this.openHTML5(opts);
      } else {
        console.log('Video provider not yet supported.');
      }
    },

    openYouTube: function (opts) {
      var self = this;
      var ytId = opts.youTubeId;
      var iframeId = '';
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var content = '<div class="js-content-block-video-placeholder youtube-video"></div>';
      var $videoContent = opts.$content;
      var $imageVideoBlock = $videoContent.siblings('.js-video-block-large-image, .js-video-block-large-video, .js-video-block-large-pc, .js-video-block-large-mobile');

      self.$imageVideoBlock = $imageVideoBlock;

      function createVideoPlayerDiv($elem, i) {
        $elem.removeClass('js-content-block-video-placeholder');
        var id = $elem.attr('class') + '-' + i;

        $elem
          .parent()
          .append('<div />')
          .children('div')
          .attr('id', id);

        return id;
      }

      function addVideo(ytIdVal, iframeIdVal) {
        // global YT
        var newYTPlayer = new this.YT.Player(iframeIdVal, {
          videoId: ytIdVal,
          playerVars: {
            autoplay: 1,
            modestbranding: 0,
            controls: 1,
            showinfo: 0,
            rel: 0
          },
          events: {
            onReady: function (e) {
              if (!opts.openOverlay) {
                $imageVideoBlock.addClass('hidden');
                $videoContent.removeClass('hidden')
                  .closest('.slick-initialized')
                  .addClass('slick-initialized--video-playing');
              }
              videoBlock.player = e.target;
              videoBlock.player.playVideo();
              videoBlock.openOverlay = false; // reset option
            },
            onStateChange: function (e) {
              if (!opts.openOverlay) {
                if (e.data === 0) {
                  $imageVideoBlock.removeClass('hidden');
                  $videoContent
                    .addClass('hidden')
                    .removeClass('revealed')
                    .closest('.slick-initialized')
                    .removeClass('slick-initialized--video-playing');
                }
              }
            }
          }
        });

        return newYTPlayer;
      }

      var options = {
        content: content,
        className: 'colorbox--video',
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        onComplete: function () {
          var _this = this;

          _this.$cbox = $('#colorbox.colorbox--video', context);

          // Create a new div for the player to take over inside of the colorbox
          iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor(Number(new Date()) / 1000));

          // Remove our empty container
          $('.js-content-block-video-placeholder', _this.$cbox).remove();

          // Instantiate the video
          addVideo(ytId, iframeId);

          // add container to make video embed responsive
          $('iframe', _this.$cbox).wrap('<div class="embed-container"></div>');

          var resizeFn = _.debounce(function () {
            $.colorbox.resize();
          }, 100);

          resizeFn();
          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();
        }
      };

      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        $videoContent.addClass('revealed');
        $videoContent.html(content);
        iframeId = createVideoPlayerDiv($('.js-content-block-video-placeholder', $videoContent), Math.floor(Number(new Date()) / 1000));
        addVideo(ytId, iframeId);
        $videoContent.trigger('video.loaded');
      }
    },

    openHTML5: function (opts) {
      var content = '<div class="embed-container">' + opts.content + '</div>';
      var context = !_.isEmpty(opts.context) ? opts.context : document;
      var $videoContent = opts.$content;
      var options = {
        content: content,
        className: 'colorbox--video',
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        maxHeight: 768,
        onComplete: function () {
          var _this = this;

          _this.$cbox = $('#colorbox.colorbox--video', context);
          // add container to make video embed responsive
          $('video', _this.$cbox).wrap('<div class="embed-container"></div>');
          var resizeFn = _.debounce(function () {
            $.colorbox.resize();
          }, 100);

          resizeFn();
          // show close button once wonky animation ends
          $('#cboxClose', _this.$cbox).show();
          $('video', _this.$cbox).get(0).play();
        }
      };

      if (opts.openOverlay) {
        generic.overlay.launch(options);
      } else {
        var $videoContentBlock = $videoContent.find('video', context);

        $videoContent.html(opts.content);
        $videoContentBlock.length > 0 ? $videoContentBlock.get(0).play() : false;
        $videoContent.trigger('video.loaded');
      }
    },

    openVimeo: function (opts) {
      var vimeoId = opts.vimeoId;
      var url = '/bumble-embed/vimeo/' + vimeoId;

      $.ajax(url, {
        success: function (data) {
          var html = data['html'];
          var content = '<div class="embed-container">' + html + '</div>';

          generic.overlay.launch({
            content: content,
            className: 'colorbox--video',
            height: 'auto',
            width: '100%',
            maxWidth: 1024,
            maxHeight: 768
          });
        }
      });
    }
  };

  /**
   * We initialize each type of video separately because
   * we don't know if/when their respective libraries are loaded
   */

  $(document).on('youtubeIframeAPI.loaded', function () {
    var $YTElements = $('.js-video').filter("[data-video-provider='youtube']");

    if ($YTElements.length) {
      videoBlock.setup($YTElements, document);
    }
  });

  $(document).on('pauseVideo', function () {
    videoBlock.pauseVideo();
  });

  $(document).on('stopVideo', function () {
    videoBlock.stopVideo();
  });

  $(document).on('playVideoInOverlay', function () {
    videoBlock.openOverlay = true;
  });

  var $HtmlElements = $('.js-video').filter("[data-video-provider='html5']");

  if ($HtmlElements.length) {
    videoBlock.setup($HtmlElements, document);
  }

  var $VimeoElements = $('.js-video').filter("[data-video-provider='vimeo']");

  if ($VimeoElements.length) {
    videoBlock.setup($VimeoElements, document);
  }
})(jQuery, window.site || {}, window.generic || {});
